import React, { useContext } from 'react';
import './style.scss';
import { Grid } from '@material-ui/core';
import Button from '../Button';
import cx from 'classnames';
import Badge from '../Badge';
import { LangContext } from '../Layout';

export default function Methodology({ methodology }) {
  const { language } = useContext(LangContext);

  const { methodologies } = methodology;
  return (
    <div className="methodology-content">
      <div className="methodology-content__top">
        <div className="methodology-content__top__title">
          <Badge text={methodology['headline_' + language]} />
        </div>
        <div className="methodology-content__top__subtitle">
          {methodology['subHeadline_' + language]}
        </div>
        <div className="methodology-content__top__description">
          {methodology['description_' + language]}
        </div>
      </div>
      <Grid container justify="center" spacing={0} className="circles">
        <Grid
          lg={3}
          md={6}
          xs={12}
          item
          className="methodology-content__circles"
        >
          <div className="methodology-content__circles__circle" />
        </Grid>
        <Grid
          lg={3}
          md={6}
          xs={12}
          item
          className="methodology-content__circles"
        >
          <div className="methodology-content__circles__circle" />
          <div className="methodology-content__circles__circle" />
        </Grid>
        <Grid lg={3} md={6} xs={12} item>
          <div className="methodology-content__circles">
            <div className="methodology-content__circles__circle" />
          </div>
          <div className="methodology-content__circles">
            <div className="methodology-content__circles__circle" />
            <div className="methodology-content__circles__circle" />
          </div>
        </Grid>
        <Grid lg={3} md={6} xs={12} item>
          <div className="methodology-content__circles">
            <div className="methodology-content__circles__circle" />
            <div className="methodology-content__circles__circle" />
          </div>
          <div className="methodology-content__circles">
            <div className="methodology-content__circles__circle" />
            <div className="methodology-content__circles__circle" />
          </div>
        </Grid>
      </Grid>
      <div className="methodology-content__right">
        <div className="methodology-content__right__list">
          <Grid container justify="center" spacing={0}>
            {methodologies.length !== 0 &&
              methodologies.map((element, index) => (
                <Grid
                  lg={3}
                  md={6}
                  xs={12}
                  key={index}
                  item
                  className={cx('methodology-content__right__list__item', {
                    'methodology-content__right__list__border':
                      index !== methodologies.length - 1,
                  })}
                >
                  <div className="methodology-content__right__list__item__logo">
                    <img
                      src={element.logo.publicURL}
                      alt={element['title_' + language].substring(0, 3)}
                    />
                  </div>
                  <div
                    className={cx(
                      'methodology-content__right__list__item__bar',
                      {
                        'methodology-content__right__list__item__barAll':
                          index === 1 || index === 2,
                        'methodology-content__right__list__item__demiBarLeft':
                          index === 0,
                        'methodology-content__right__list__item__demiBarRight':
                          index === 3,
                      },
                    )}
                  />
                  <div className="methodology-content__right__list__item__title">
                    <>
                      {element['title_' + language].substring(
                        0,
                        element['title_' + language].lastIndexOf('&'),
                      )}
                    </>
                    <>
                      {element['title_' + language].substring(
                        element['title_' + language].lastIndexOf('&'),
                        undefined,
                      )}
                    </>
                  </div>
                  <div className="methodology-content__right__list__item__description">
                    {element['description_' + language]}
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
      <div className="methodology-content__link">
        <Button text="Read more about our methodology" to="/methodology" />
      </div>
    </div>
  );
}
